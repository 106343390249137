@use "../../config/variables.scss" as vars;

.userMessageScreen-box1 {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}
.userMessageScreen-box2 {
  overflow-y: scroll;
  height: 80vh;
  background-color: #fff;
}

.userMessageScreen-box3 {
  padding: 5px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
  cursor: pointer;
}

.userMessageScreen-box3:hover {
  background-color: #e4e1e1;
  border-radius: 8px;
}

.userMessageScreen-img {
  width: 43px;
  height: 43px;
  background-size: cover;
  border-radius: 50%;
}

.userMessageScreen-box4 {
  overflow-y: scroll;
  height: 80vh;
  background-color: #fff;
  padding: 10px;
}

.userMessageScreen-box5 {
  display: flex;
  justify-content: center;
  align-items: end;
  height: 200px;
}
.userMessageScreen-img2 {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-size: cover;
}
.userMessageScreen-txt1 {
  font-family: vars.$ExtraBold;
  text-align: center;
  font-size: 20px;
}
.userMessageScreen-txt2 {
  font-family: vars.$Medium;
  color: rgb(201, 197, 197);
}
