@use "../../config/variables.scss" as vars;
.contactScreen-Box1 {
  padding: 60px;
  @media screen and (max-width: 568px) {
    padding: 10px 0px;
  }
}

.contactScreen-Box2 {
  background-color: aquamarine;
}

.contactScreen-Text1 {
  font-family: vars.$Bold;
  font-size: 25px;
  color: vars.$primary1;
}
.contactScreen-Text2 {
  font-size: 16px;
  // color: vars.$primary1;
}
.contactScreen-Text3 {
  font-size: 16px;
  color: vars.$primary1;
  margin-bottom: 5px;
}
