@use "./config/variables.scss" as vars;

.screenBox {
  padding: 20px 80px;
  @media screen and (max-width: 578px) {
    padding: 20px 20px;
  }
}

.formLabel {
  font-family: vars.$Bold;
  font-size: 15px;
  color: vars.$primary1;
  margin-bottom: 10px;
}

.table-Action {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table-Txt {
  font-family: vars.$SemiBold;
  font-size: 12px;
}
.table-item {
  display: flex;
}
.heading_text {
  text-align: center;
  color: vars.$primary1;
  font-family: vars.$ExtraBold;
  font-size: 30px;
  @media screen and (max-width: 578px) {
    font-size: 20px;
  }
}
