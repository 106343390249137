@use "../config/variables.scss" as vars;

.LayoutHeader {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  height: 9vh;
}
.LayoutHeader-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: vars.$Bold;
  color: vars.$primary;
  font-size: 20px;
}

.sideBarUser-Box {
  height: 100vh;
  overflow: scroll;
}
.sideBarUser-Box2 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.sideBarUser-Box3 {
  height: 100px;
  width: 100px;
}

.sideBarUser-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.sideBarUser-txt1 {
  // border-bottom: 1px solid #875aa2;
  font-family: vars.$SemiBold;
  font-size: 14px;
  color: vars.$primary1;
  padding-left: 10px;
}

.sideBarUser-item-user {
  display: flex;
  flex-direction: row;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  // background-color: vars.$primary1;
  margin: 10px;
  color: black;
  font-family: vars.$Medium;
  &:hover {
    background-color: vars.$primary1;
    border-radius: 5px;
    color: vars.$secondary;
  }
  &.active {
    background-color: vars.$primary1;
    border-radius: 5px;
    color: vars.$secondary;
  }
}

.userRoute-Sider {
  overflow: auto;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: 6px;
  background-color: #fff !important;
  display: block;
  @media screen and (max-width: 578px) {
    display: none;
  }
}
.UserScreen-box1 {
  margin-left: 260;
  height: 100vh;
  overflow: scroll;
  @media screen and (max-width: 578px) {
    margin-left: none;
  }
}
