// message style---------------->

.ant-drawer-body {
  padding: 0px !important;
}

.message-bubble-right {
  border-radius: 15px 0px 15px 15px;
  padding: 10px;
  margin: 5px;
}
.message-bubble-left {
  border-radius: 0px 15px 15px 15px;
  padding: 10px;
  margin: 5px;
}

.message-bubble-left.left {
  background-color: #fff;
  align-self: flex-start;
  color: black;
}

.message-bubble-right.right {
  background-color: #b589ce;
  align-self: flex-end;
  color: #ffffff;
}

// chat drawer========>

.userChatDrawer-box1 {
  background-color: rgb(230 233 232);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}
.userChatDrawer-box2 {
  display: flex;
  gap: 20px;
  align-items: center;
}
.userChatDrawer-Img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.userChatDrawer-box3 {
  height: 63vh;
  width: 100%;
  background-color: #eef1f3;
}

.userChatDrawer-box33 {
  height: 77vh;
  width: 100%;
  background-color: #eef1f3;
}

.message-Box {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  height: 63vh;
  padding: 10px;
}
.message-Box2 {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  height: 77vh;
  padding: 10px;
}
.userChatDrawer-txt1 {
  text-align: center;
}

.userChatDrawer-Img2 {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.userChatDrawer-box4 {
  padding: 5px;
  background-color: rgb(230, 233, 232);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.messageBubble-box1 {
  display: flex;
  align-items: center;
}
