@use "../../config/variables.scss" as vars;

.Dashboard-cards {
  //   background-color: #cbe5e1a3;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}

.Dashboard-cardBox1 {
  background-color: #875aa2;
  height: 50px;
  width: 50px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 30px;
  color: vars.$secondary;
}

.Dashboard-cardtxt1 {
  font-size: 14px;
  color: rgb(90, 90, 90);
}

.Dashboard-cardtxt2 {
  font-size: 25px;
  //   color: vars.$primary;
  font-family: vars.$Bold;
}

.Dashboard-ViewChart {
  height: 50vh;
}
