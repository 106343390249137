@use "../../config/variables.scss" as vars;

.userTransactionViewScreen-txt1 {
  font-family: vars.$Bold;
  font-size: 20px;
}
.userTransactionViewScreen-txt2 {
  font-family: vars.$Bold;
  font-size: 15px;
  margin-top: 10px;
}
.userTransactionViewScreen-txt3 {
  border: 2px solid vars.$primary1;
  border-radius: 7px;
  height: 33px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  font-family: vars.$Regular;
  font-size: 13px;
}

.userTransactionViewScreen-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}

.userTable {
  table {
    font-family: vars.$Regular;
    border-collapse: collapse;
    width: 100%;
  }
  td,
  th {
    border-bottom: 2px solid vars.$primary1;
    text-align: left;
    padding: 8px;
  }
}
