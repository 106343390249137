@use "../../config/variables.scss" as vars;

.loginScreen {
  padding: 50px 0px 90px 0px;
}
.loginScreen-Box1 {
  width: 100%;
  overflow: hidden;
  // margin-bottom: 20px;
  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
}
.loginScreen-txt1 {
  text-align: center;
  font-size: 20px;
  font-family: vars.$Bold;
}
.loginScreen-txt2 {
  // text-align: center;
  font-size: 14px;
  font-family: vars.$Bold;
  color: vars.$primary1;
}

.loginScreen-Btn {
  background-color: vars.$primary1;
  color: vars.$secondary;
  font-family: vars.$Bold;
}

//////Forgot Password////////////
.EnterEmail-text {
  text-align: center;
  color: vars.$primary1;
  font-family: vars.$Bold;
}
.logo {
  width: 100%;
  height: 200px;
  object-fit: contain;
}
.EnterEmail-title {
  font-size: 24px;
}
.EnterEmail-desc {
  font-size: 13px;
  margin: 20px 0px 15px 0px;
}
.EnterEmail-textLast {
  font-size: 15px;
  margin-top: 20px;
}
