@use "../../config/variables.scss" as vars;

.plan-card {
  border: 1px solid #d2d7e2;
  margin-top: 15px;
  margin-left: 5px;
  table {
    font-family: vars.$Regular;
    border-collapse: collapse;
    width: 100%;
    color: #222f3d;
  }

  td,
  th {
    border: 1px solid #d2d7e2;
    border-left: none;
    border-right: none;
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.08);
  }

  .ant-card .ant-card-body {
    padding: 30px 0px 0px 0px !important;
    border-radius: 0px 0px !important;
  }
}
.plan-card2 {
  color: #fff;
  margin-left: 5px;
  table {
    font-family: vars.$Regular;
    border-collapse: collapse;
    width: 100%;
    color: #fff;
  }

  td,
  th {
    border: 1px solid #d2d7e2;
    border-left: none;
    border-right: none;
    text-align: center;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #044f52;
  }
  tr:nth-child(odd) {
    background-color: #024244;
  }

  .ant-card .ant-card-body {
    padding: 30px 0px 0px 0px !important;
    border-radius: 0px 0px !important;
    background-color: #044f52 !important;
  }
}

.plan-card-heard {
  font-size: 1em;
  position: absolute;
  top: -27px;
  right: 22px;
  height: 45px;
  width: 82%;
  background-color: #222f3d;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: vars.$ExtraBold;
}

.plan-card h3 {
  font-size: 1.2em;
  color: #222f3d;
}
.plan-card2 h3 {
  font-size: 1.1em;
  color: #fff;
}
.plan-card2 .sub-head {
  font-size: 2.5em;
  color: #fff;
  font-family: vars.$ExtraBold;
}
.plan-card .sub-head {
  font-size: 2.5em;
  color: #222f3d;
  font-family: vars.$ExtraBold;
}

.pay_button {
  color: #4caf50;
  cursor: pointer;
  text-align: center;
  margin: 25px;
}

.head-container {
  display: flex;
  justify-content: center;
  align-items: end;
  margin-bottom: 25px;
}

.plansData-box1 {
  padding: 60px 30px;
  @media screen and (max-width: 568px) {
    padding: 10px 0px;
  }
}
.plansData-txt {
  color: vars.$primary1;
  font-size: 25px;
  font-family: vars.$Bold;
}
