.userCard-box1 {
  text-align: center;
}
.userCard-box2 {
  width: 200px;
  display: flex;
  justify-content: space-between;
}
.userCard-txt {
  text-align: start;
}
