@use "../../config/variables.scss" as vars;

.userNotificationScreen-box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.userNotificationScreen-box2 {
  height: 60px;
  width: 80px;
  margin-right: 10px;
}

.userNotificationScreen-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 20%;
}
