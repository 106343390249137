@use "../../config/variables.scss" as vars;

.aboutUsScreen-txt1 {
  font-family: vars.$Regular;
  font-size: 16px;
}
.aboutUsScreen-txt2 {
  font-family: vars.$Bold;
  font-size: 25px;
  color: vars.$primary1;
}
.aboutUsScreen-box1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutUsScreen-box2 {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid vars.$primary1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.aboutUsScreen-box3 {
  padding: 60px 30px;
  @media screen and (max-width: 568px) {
    padding: 10px 0px;
  }
}
