//colors
$primary: #fff0c932;
$primary1: #6e2e92;
$secondary: #f2f2f2;
//font family
$Regular: "DMSans-Regular";
$Italic: "DMSans-Italic";
$Bold: "DMSans-Bold";
$ExtraBold: "DMSans-ExtraBold";
$SemiBold: "DMSans-SemiBold";
$Medium: "DMSans-Medium";
$LogoBold: "NotoSerif-ExtraBold";
