@use "../../config/variables.scss" as vars;
.userHomeScreen-box1 {
  background-color: lightgrey;
  height: 200px;
  position: absolute;
}

.userHomeScreen-box2 {
  width: 100%;
  top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.userHomeScreen-box3 {
  width: 90%;
  background-color: #fff;
  // height: 138px;
  border-radius: 10px;
  position: relative;
}

.userHomeScreen-box4 {
  background-color: lightgreen;
  height: 60vh;
}
.userHomeScreen-box5 {
  height: 150px;
  width: 150px;
  object-fit: cover;
}

.userHomeScreen-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 10%;
}
