@use "../../config/variables.scss" as vars;
.PageHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #fff;
  margin-top: 5px;
  border-radius: 10px;
  padding: 10px;
  flex-wrap: wrap;
}

.PageHeaderBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.PageHeader-back {
  font-size: 30px;
  padding: 5px;
  border-radius: 10px;
  margin-right: 20px;
  background-color: #875aa2;
  display: flex;
  justify-content: center;
}

.PageHeader-Box {
  font-family: vars.$Regular;
}

.PageHeader-text1 {
  font-size: 20px;
  font-family: vars.$SemiBold;
}
.PageHeader-toggle-box1 {
  display: none;
  @media screen and (max-width: 578px) {
    display: block;
  }
}
.PageHeader-toggle-box2 {
  padding: 10px;
}
.PageHeader-toggle-box3 {
  transition: background-color 0.3s;
  border-bottom: 1px solid grey;
}
.PageHeader-toggle-box2:hover {
  background-color: #e4e1e1;
  border-radius: 8px;
}
