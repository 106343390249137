* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: "DMSans-Bold";
  src: url("./assets/fonts/DMSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-ExtraBold";
  src: url("./assets/fonts/DMSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-SemiBold";
  src: url("./assets/fonts/DMSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Regular";
  src: url("./assets/fonts/DMSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Medium";
  src: url("./assets/fonts/DMSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "DMSans-Italic";
  src: url("./assets/fonts/DMSans-Italic.ttf") format("truetype");
}

.overFlow-Box2::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.table-Action {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-Txt {
  font-family: "DMSans-SemiBold";
  font-size: 12px;
}

.table-item {
  display: flex;
}
