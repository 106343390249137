@use "../../config/variables.scss" as vars;
.websiteHeader-Box1 {
  width: 100%;
  height: 80px;
  background-color: transparent;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.websiteHeader-Box2 {
  width: 100%;
  height: 80px;
  padding: 30px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 786px) {
    padding: 0px;
  }
}
.websiteHeader-Box3 {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media only screen and (max-width: 992px) {
    display: none !important;
  }
}
.websiteHeader-Txt {
  font-family: vars.$Medium;
  font-size: 16px;
  color: black;
  cursor: pointer;
  display: flex;
  // justify-content: space-between;
}

.img-header {
  width: 100px;
  object-fit: cover;
  cursor: pointer;
}
.websiteHeadet_Drawer_img-header {
  width: 180px;
  height: 110px;
  object-fit: cover;
  cursor: pointer;
}
.webSiteHeader-Box4 {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 16px;
  @media only screen and (min-width: 992px) {
    display: none;
  }
}
.websiteHeader-Box4 {
  flex-direction: column;
  display: flex;
  padding: 10px;
  display: flex;
}

.websiteHeader-Box5 {
  text-align: center;
}
.websiteHeadet_Drawer_box1 {
  padding: 10px;
  &:hover {
    background-color: rgb(233, 231, 231);
    border-radius: 10px;
  }
}
