@use "../../config/variables.scss" as vars;
.registration-card {
  background-color: transparent;
  border: none;
}
// step 33
.Registration-StepThree-Box1 {
  text-align: center;
}
.Registration-StepThree-Box2 {
  text-align: center;
  line-height: 35px;
  font-family: vars.$SemiBold;
}

.Registration-StepThree-image {
  height: 150px;
}
// image upload
.upload_image-Box1 {
  height: 100px;
  cursor: pointer;
}
.upload_Image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.Registration-txt {
  text-align: center;
  font-family: vars.$SemiBold;
  color: vars.$primary1;
}

.StepOne_box1 {
  height: 165px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.StepOne_box2 {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
